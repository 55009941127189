import React, { useState } from 'react';
import { Link } from 'gatsby';
import logo from '../../images/logo.svg';
import Obfuscate from 'react-obfuscate';

export default function Header(props) {
  const { menuLinks } = props;
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <React.Fragment>
      <div className="flex bg-gray-300 justify-center">
        <div className="max-w-screen-xl flex justify-end flex-grow">
          <h6 className="inline-block py-2 pr-10 mb-0 flex">
            <div>
              <Obfuscate tel="(818) 738-7319" className="text-blue-700" />{' '}
              &nbsp;&nbsp;{' '}
            </div>
            <div>
              <Obfuscate
                email="info@custompropshop.com"
                className="text-blue-700"
              />
            </div>
          </h6>
        </div>
      </div>
      {/* <div className="banner-covid flex bg-red-700 text-white px-8 py-2 text-center justify-center">
        COVID-19 Update: We are open and taking appropriate measures to keep our
        team safe during fabrication and install.
      </div> */}
      <div className="flex justify-center container">
        <nav
          id="site-menu"
          className={`flex flex-grow flex-col md:flex-row w-full justify-between items-end px-1 md:px-6 pt-3 pb-1 align-bottom max-w-screen-xl`}
        >
          <div className="w-full md:w-auto self-start md:self-center flex flex-row md:flex-none flex-no-wrap justify-between items-center pr-4">
            <Link to={'/'} className="no-underline py-1">
              <img
                alt="Custom Prop Shop Logo"
                src={logo}
                height={50}
                width={300}
                className="brand"
                id="logo"
              />
            </Link>
            <button
              id="menuBtn"
              className={`hamburger block md:hidden focus:outline-none pt-2 ${
                menuIsOpen ? 'open' : ''
              }`}
              type="button"
              onClick={() => setMenuIsOpen(!menuIsOpen)}
            >
              <span className="hamburger__top-bun"></span>
              <span className="hamburger__bottom-bun"></span>
            </button>
          </div>
          <div
            id="menu"
            className={`${
              menuIsOpen ? 'flex' : ''
            } w-full md:w-auto self-end md:self-center md:flex flex-col md:flex-row items-center h-full md:py-0 md:pb-0 pt-2 ${
              menuIsOpen ? '' : 'hidden'
            } `}
          >
            {menuLinks.map(link => (
              <Link
                key={link.name}
                to={link.link}
                className="hover:text-red-700 text-black font-bold hover:no-underline text-base w-full no-underline md:w-auto last:pr-1 md:pr-4 lg:pr-6 md:pt-2"
                activeClassName="text-red-700"
              >
                {link.name.toUpperCase()}
              </Link>
            ))}
          </div>
        </nav>
      </div>
    </React.Fragment>
  );
}
