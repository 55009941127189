const config = {
  siteTitle: 'Custom Props Made By Professional Prop Makers', // Site title.
  siteTitleShort: 'Custom Props', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Custom Prop Shop', // Alternative site title for SEO.
  siteLogo: '/icon.png', // Logo used for SEO and manifest.
  siteUrl: 'https://custompropshop.com', // Domain of your website without pathPrefix.
  pathPrefix: '/', // Prefixes all links..
  siteDescription:
    'We use the best prop makers to create custom props for trade shows, marketing, advertising, events, public art, private collections and much more.', // Website description used for RSS feeds/meta description tag.
  siteRssTitle: 'Custom Prop Shop RSS feed', // Title of the RSS feed
  googleAnalyticsID: 'UA-1772620-3', // GA tracking ID.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'DD/MM/YYYY', // Date format for display.
  userLinks: [],
  copyright: 'Copyright © 2021. Custom Prop Shop', // Copyright string for the footer of the website and RSS feed.
  themeColor: '#b13034', // Used for setting manifest and progress theme colors.
  backgroundColor: '#e0e0e0', // Used for setting manifest background color.
};

// Validate

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === '/') {
  config.pathPrefix = '';
} else {
  // Make sure pathPrefix only contains the first forward slash
  config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, '')}`;
}

// // Make sure siteUrl doesn't have an ending forward slash
// if (config.siteUrl.substr(-1) === "/")
//   config.siteUrl = config.siteUrl.slice(0, -1)

module.exports = config;
