import React from 'react';
import { Helmet } from 'react-helmet';
import config from '../../data/SiteConfig';
import './index.css';
import Header from '../components/header/header';
import { StaticQuery, Link, graphql } from 'gatsby';

// if (typeof window !== `undefined`) {
//   require("bootstrap/dist/css/bootstrap.min.css")
// }

export default function Layout({ children }) {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
              menuLinks {
                name
                link
              }
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <div className="flex flex-col min-h-screen">
            <Helmet>
              <meta name="description" content={config.siteDescription} />
              <html lang="en" />
              <style>
                @import
                url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');
              </style>
              <script type="application/ld+json">
                {`{
                    "@context": "https://schema.org",
                    "@type": "Organization",
                    "url": "https://custompropshop.com",
                    "logo": "https://custompropshop.com/icon.png",
                    "@id":"https://custompropshop.com/#logo", 
                    "name": "Custom Prop Shop"
                  }`}
              </script>
            </Helmet>
            <Header menuLinks={data.site.siteMetadata.menuLinks} />
            <div className="flex-grow pt-4 pb-5">{children}</div>
            <footer className="container-fluid footer py-4">
              <div className="container d-flex justify-content-between">
                <p className="my-auto text-sm">
                  Copyright © 2021 Custom Prop Shop
                </p>
                <nav className="flex flex-col md:flex-row font-medium">
                  {data.site.siteMetadata.menuLinks.map(link => (
                    <Link
                      key={link.name}
                      to={link.link}
                      className="text-white w-full no-underline md:w-auto py-2 pl-4"
                    >
                      {link.name}
                    </Link>
                  ))}
                </nav>
              </div>
            </footer>
          </div>
        </React.Fragment>
      )}
    />
  );
}
